require(['jquery'], function ($) {

  // if ($(".xm-grid-product").length > 0 && $('button.btn-cart').length > 0) {
  //   $('body').append("<div class='add-cart-fixed' style='display: none;'><span>" + $('button.btn-cart').text() + "</span></div>");
  //   $('body').on('click', '.add-cart-fixed', function () {
  //     $([document.documentElement, document.body]).animate({
  //       scrollTop: $(".product-shop").offset().top
  //     }, 1000);

  //     $('.product-options').addClass('border-out');

  //   });
  // }

  // var isScrolledIntoView = function (elem) {
  //   var docViewTop = $(window).scrollTop();
  //   var docViewBottom = docViewTop + $(window).height();

  //   var elemTop = $(elem).offset().top;
  //   var elemBottom = elemTop + $(elem).height();

  //   return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  // };

  // var halfScreenSize = ($(document).width() * 0.5);
  // $(window).scroll(function () {
  //   if ($('button.btn-cart').length > 0) {
  //     var isVisibleOnScreen = isScrolledIntoView('button.btn-cart');
  //     if (isVisibleOnScreen) {
  //       $('.add-cart-fixed').hide();
  //       $('.add-cart-fixed').width('50%');
  //     } else {
  //       if ($('.add-cart-fixed').width() === halfScreenSize) {
  //         $('.add-cart-fixed').show().animate({ width: '100%' });
  //       }
  //     }
  //   }
  // });

  if ($("#mini-cart").length > 0) {
    $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
  }

  if ($('.mobile-header-container .userSwitch').length) {
    $('.mobile-header-container .item-switch-customer').addClass('admin');
  }
  $(".form-search .button.button-search").html("<i class='fa fa-search'></i>");
  if ($(".logo-wrapper").length > 0) {
    $('.logo-wrapper').append("<div class='show-below-960 right nav-search'>" + $(".search-wrapper").html() + "</div>");
    if ($("#mini-cart").length > 0) {
      $('.logo-wrapper').prepend("<div class='show-below-960 right nav-cart'>" + $("#mini-cart").html() + "</div>");
    }
  }

  if ($(".variant-option").length > 0) {
    if ($(".variant-option .swatches-con .swatch img").length > 0) {
      $(".variant-option .swatches-con").css('display', 'block');
    }
  }

  $('.main-header-container .search-column input#search').on('focusin focusout', function (e) {
    if (e.type === "focusin") {
      $(this).parentsUntil('.main-header-container', '.search-column').addClass('open-search');
    }
    else if (e.type === "focusout") {
      $(this).parentsUntil('.main-header-container', '.search-column').removeClass('open-search');
    }
  });

  $(window).on('resize', onResize);

  $(document).ready(function () {
    onResize();
  });

  var onResize = function () {
    if ($(window).width() < 960) {
      $(".nav-search .form-search .button.button-search").on("click", function (e) {
        if (!$(".nav-search").hasClass("fly-out")) {
          e.preventDefault();
          $(".nav-search").addClass("fly-out");
          $(".nav-search .form-search .input-text").focus();
          $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
        }
      });
    }
  };

  $(document).on('click', '.nav-search .button-close', function (e) {
    e.preventDefault();
    $(".nav-search").removeClass("fly-out");
  });

  let mainContainerStyle;
  const originalLogoSrc = $('#header .logo img').attr('src');
  const newLogoSrc = originalLogoSrc.replace('/global/', '/ckfinder/images/').replace('_reverse', '');

  $('#header').addClass('sticky');
  //$('#header .logo img').attr('src', newLogoSrc);

  mainContainerStyle = 'padding-top: ' + ($('#header').outerHeight() + 30) + 'px !important';
  $('.main-container').attr('style', mainContainerStyle);
  $('.xm-grid-product').addClass('container');

  window.onbeforeprint = function () {
    $('.main-container').removeAttr('style');
  };
  window.onafterprint = function () {
    $('.main-container').attr('style', mainContainerStyle);
  };

  const headerHeight = $('#header').outerHeight();

  $(window).scroll(function () {
    const offsetTop = headerHeight - $(window).scrollTop();
    if (offsetTop <= 0) {
      $('#header').addClass('scrolling');
      //$('#header .logo img').attr('src', newLogoSrc);
      $('.opc-progress-container.col-right.sidebar').removeAttr('style');
    }
    else {
      $('#header').removeClass('scrolling');
      //$('#header .logo img').attr('src', originalLogoSrc);
      $('.opc-progress-container.col-right.sidebar').css('top', (offsetTop + 30) + 'px');
    }
  });


});